<template>
  <v-row dense>
    <v-col cols="12">
      <v-text-field
        aut-report-name
        dense
        name="name"
        label="Name"
        persistent-hint
        hint="Give this report a name"
        required
        :rules="applicableRules"
        v-model="reportDefinition.name"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-textarea
        outlined
        aut-report-description
        name="description"
        :rows="3"
        auto-grow
        v-model="reportDefinition.description"
        label="Description"
        persistent-hint
        hint="Type a description to help understand the purpose of this report"
      ></v-textarea>
    </v-col>
  </v-row>
</template>
<script>
import { rules } from "@/util.js";
import { STORE_CONSTS } from "@/views/applications/report/store";
const debug = require("debug")("atman.components.report_name"); // eslint-disable-line
export default {
  name: "ReportName",
  data() {
    return {
      applicableRules: [rules.mandatory()],
    };
  },
  computed: {
    reportDefinition: {
      get() {
        if (!this.$store.state[STORE_CONSTS.REPORT_EDITOR_STORE]) {
          return {};
        }
        return this.$store.state[STORE_CONSTS.REPORT_EDITOR_STORE][
          STORE_CONSTS.DEFINITION
        ];
      },
      set(value) {
        this.$store.commit(
          `${STORE_CONSTS.REPORT_EDITOR_STORE}/${STORE_CONSTS.DEFINITION}`,
          value
        );
      },
    },
  },
  created() {
    debug(`In created of ReportName`);
  },
  mounted() {
    debug(`In mounted of ReportName`);
  },
  methods: {},
};
</script>
